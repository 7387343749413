import React from 'react';
import './FeaturesCard.css'; // Assuming you have a CSS file to style the card

function FeaturesCard(props) {
  const { title, description, image } = props;

  return (
    <div className="features-card">
      <img src={image} alt={title} className="features-card-image"></img>
      <div className="features-card-content">
        <h3 className="features-card-title">{title}</h3>
        <p className="features-card-description">{description}</p>
      </div>
    </div>
  );
}

export default FeaturesCard;
