import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import ImageCard from '../../Components/ImageCard/ImageCard'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import { Container, Row, Col, Button } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgStage from '../../assets/Products/SuperSwivels/stage.jpg'
import imgInline from '../../assets/Products/SuperSwivels/inline.jpg'
import img90Degree from '../../assets/Products/SuperSwivels/degree90.jpg'
import imgDualPlane from '../../assets/Products/SuperSwivels/DualPlane.jpg'
import imgHighPressure from '../../assets/Products/SuperSwivels/highPressure.jpg'
import imgBSPP from '../../assets/Products/SuperSwivels/bspp.jpg'
import imgStainlessSteel from '../../assets/Products/SuperSwivels/steel.jpg'
import imgCarWash from '../../assets/Products/SuperSwivels/carwash.jpg'
import imgHeavyDuty from '../../assets/Products/SuperSwivels/heavyDuty.jpg'
import imgSafety from '../../assets/Products/SuperSwivels/safety.png'
import imgDurability from '../../assets/Products/SuperSwivels/durability.png'
import imgVersatile from '../../assets/Products/SuperSwivels/versatile.png'
import imgManuever from '../../assets/Products/SuperSwivels/manuever.png'

const SuperSwivels = () => {
    const isMobile = window.innerWidth <= 768;
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    const featuresData = [
        {
            image: imgDurability,
            title: "Enhanced Durability",
            description: "Designed to withstand extreme conditions, Super Swivels are built with high-quality materials that ensure long-lasting performance, reducing the need for frequent replacements."
        },
        {
            image: imgVersatile,
            title: "Versatile Applications",
            description: "Suitable for a wide range of industries, including plumbing, automotive, and industrial settings, these swivels provide reliable solutions for various operational needs.",
        },
        {
            image: imgSafety,
            title: "Safety Assurance",
            description: "Engineered to maintain optimal pressure and prevent leaks, Super Swivels enhance safety in high-pressure environments, ensuring peace of mind during operation.",
        },
        {
            image: imgManuever,
            title: "Improved Maneuverability",
            description: "The unique design of the swivels allows for smooth rotation and flexibility, facilitating easier handling and installation in tight spaces.",
        },
    ]

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"Super Swivels: Precision Engineered Solutions for Every Application"}
                    paragraph={"Explore Our Range of High-Performance Swivels Designed for Durability and Efficiency."}
                />
            </div>

            <div>
                <SectionHeader title={"Discover Our Innovative Swivels"}
                    subtitle={"Our range of swivels is meticulously engineered to meet the diverse needs of various applications. From standard pressure to high-performance models, each swivel is designed for reliability, durability, and efficiency. Explore our selection to find the perfect solution for your industrial, plumbing, or car wash needs, ensuring optimal performance and safety in every use."}
                />
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard
                                image={imgInline}
                                title={"Inline Standard Pressure"}
                                desc={"The Inline Standard Pressure swivels are designed for general applications where standard pressure is required. They provide reliable performance and are suitable for various industrial uses."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard
                                image={img90Degree}
                                title={"90° Standard Pressure"}
                                desc={"These swivels allow for a 90-degree turn in piping systems, making them ideal for tight spaces. They maintain standard pressure capabilities while offering flexibility in installation."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard
                                image={imgDualPlane}
                                title={"Dual Plane 90° Standard Pressure"}
                                desc={"The Dual Plane 90° Standard Pressure swivels are engineered for applications requiring dual-plane rotation. They are perfect for complex setups where multiple angles are needed without compromising pressure."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard
                                image={imgHighPressure}
                                title={"High Pressure"}
                                desc={"High Pressure swivels are built to withstand extreme pressure conditions. They are essential for heavy-duty applications where safety and durability are paramount."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard
                                image={imgBSPP}
                                title={"Inline and 90° BSPP"}
                                desc={"These swivels come in both inline and 90° configurations with BSPP (British Standard Parallel Pipe) threads, making them versatile for various plumbing and hydraulic systems."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard
                                image={imgStainlessSteel}
                                title={"Stainless Steel Inline and 90°"}
                                desc={"Made from high-quality stainless steel, these swivels offer excellent corrosion resistance. They are available in both inline and 90° designs, suitable for food processing and marine applications."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                            <ImageCard
                                image={imgHeavyDuty}
                                title={"Extra Heavy Duty Inline and 90°"}
                                desc={"Designed for the toughest environments, Extra Heavy Duty swivels provide maximum strength and reliability. They are ideal for industrial applications that demand high performance."}
                            />
                        </Col>
                        <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                            <ImageCard
                                image={imgCarWash}
                                title={"Car Wash Inline and 90º"}
                                desc={"The Car Wash Inline and 90º swivels are specifically designed for car wash applications. They facilitate smooth water flow and maneuverability, ensuring efficient cleaning without kinks or pressure loss. These swivels are built to withstand the rigors of frequent use in car wash systems, providing durability and reliability in high-pressure environments."}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <SectionHeader
                    title={"Advantages of Using Super Swivels"}
                    subtitle={"Discover the Key Benefits That Enhance Performance and Reliability in Your Applications."}
                />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>
            <br /><br /><br />
        </div >
    )
}

export default SuperSwivels