import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';

import imgStage from '../../../assets/Products/HosesAndHoseLines/Accessories/Stage.jpg'
import imgProtectionAgainstMechanicalLoads from '../../../assets/Products/HosesAndHoseLines/Accessories/ProtectionAgainstMechanicalLoads.jpg'
import imgProtectionFromOilJetInjury from '../../../assets/Products/HosesAndHoseLines/Accessories/ProtectionFromOilJetInjury.jpg'
import imgAccidentPrevention from '../../../assets/Products/HosesAndHoseLines/Accessories/AccidentPrevention.jpg'
import imgHoseBundling from '../../../assets/Products/HosesAndHoseLines/Accessories/HoseBundling.jpg'
import imgShrinkHoses from '../../../assets/Products/HosesAndHoseLines/Accessories/ShrinkHoses.jpg'
import imgInstallation from '../../../assets/Products/HosesAndHoseLines/Accessories/Installation.jpg'
import imgPtfeHoses from '../../../assets/Products/HosesAndHoseLines/Accessories/PtfeHoses.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/Accessories/MetalHose.jpg'
import imgIndustrialHose from '../../../assets/Products/HosesAndHoseLines/Accessories/IndustrialHose.jpg'



const Accessories = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const accessories1 = {
        image: imgProtectionAgainstMechanicalLoads,
        title: 'Protection against mechanical loads and heat',
        description: 'Hose lines can be exposed to extreme loads. HANSA‑FLEX provide solutions for protection against rubbing, kinking and heat. These solutions prevent damage due to rubbing, kinking and high temperatures.',
        listItems: ["Rubbing and kinking protection using various materials", "Heat protection up to 260 °C using silicate, silicone or glass fibre", "Air conditioning hose made from glass fibre protects from condensation"]
        , buttonName: "ALL PRODUCTS"
    }

    const accessories2 = {
        image: imgProtectionFromOilJetInjury,
        title: 'Protection of people from oil jet injury',
        description: 'A jet of oil escaping from a defective hydraulic hose line can lead to serious injuries. An extremely tear-resistant textile protection hose blocks these jets and conducts the escaping oil safely away.',
        listItems: ["Protection hose made from high-strength nylon", "Extremely abrasion resistant and flame retardant", "Internal diameters of up to 127 mm"]
        , buttonName: "ALL PRODUCTS"
    }

    const accessories3 = {
        image: imgAccidentPrevention,
        title: 'Accident prevention using hose restraint devices',
        description: 'In addition to traditional hose restraints, HANSA‑FLEX also offers its STOP LOCK hose retention system for effective protection against pulled-out, whipping hoses. These steel rope loops can be adjusted to suit the hose diameter, are simple to install and provide high levels of protection.',
        listItems: ["Hose retention system for protection against whipping hoses", "STOP LOCK system has the ability to be adjusted to suit the hose diameter"]
        , buttonName: "ALL PRODUCTS"
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Bundling of hoses",
            listItems: ["One-piece lashing strap with clamp lock", "Retaining band with Velcro fastener and mounting eye", "Flame-retardant hose bundling with Velcro fastener"],
            buttonName: "PRODUCTS",
            image: imgHoseBundling
        },
        {
            title: "Thermoplastic shrink hoses",
            listItems: ["With or without adhesive coating", "Black or transparent", "Shrinking temperature between 90 °C and 125 °C"],
            buttonName: "PRODUCTS",
            image: imgShrinkHoses
        },
        {
            title: "Other accessories for installation",
            listItems: ["Toolkit for installation of hose retention system", "Self-adhesive tape for hose retention system", "Silicone rubber wrapping tape for heat protection hose"],
            buttonName: "PRODUCTS",
            image: imgInstallation
        },
    ];

    const tabTitles = [
        { title: "Hose bundling", eventKey: "Hose bundling" },
        { title: "Shrink hoses", eventKey: "Shrink hoses" },
        { title: "Installation", eventKey: "Installation" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"PERFECT PROTECTION FOR HOSES WITH ACCESSORIES FROM HANSA‑FLEX"}
                    paragraph={"Hose lines from HANSA‑FLEX impress in numerous applications through their excellent quality. Products from the HANSA‑FLEX accessory portfolio not only protect susceptible hose parts in situations where particularly high mechanical load requirements or the effects of high temperatures present problems, but also protect people, for example from oil jet injuries. A wide range of products and expert advice ensure the selection implementation of effective and efficient protection solutions."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"VARIOUS TYPES OF PROTECTION AND FIELDS OF APPLICATION"}
                />
                <ImageText
                    data={accessories1} />
                <ImageText
                    data={accessories2} left={false} />
                <ImageText
                    data={accessories3} />
            </div>

            <div>
                <SectionHeader
                    title={"FIRST-CLASS ADVICE FROM EXPERIENCED EXPERTS"}
                    subtitle={"The experts at HANSA‑FLEX are the people to contact if you want advice on hose line safety. In discussion with you, they will find the optimum product for your intended application. If you wish, we can also produce special solutions for your specific requirements."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"FURTHER SOLUTIONS IN THE FIELD OF HOSE PROTECTION AND SAFETY"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPtfeHoses}
                                title="PTFE hoses with high chemical resistance"
                                desc={"Because of their high chemical resistance against most media, PTFE hoses from HANSA‑FLEX are the first choice for all challenging applications."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMetalHose}
                                title="Metal hoses with high temperature tolerance"
                                desc={"Metal hoses excel in particular with their ability to withstand high mechanical loads, strength under vacuum and high temperature tolerance."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndustrialHose}
                                title="Industrial hoses in the highest quality"
                                desc={"HANSA‑FLEX can produce industrial hoses to individual customer requirements quickly and competently in its own specialist hose workshop."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>

    );
}


export default Accessories