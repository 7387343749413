import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import imgFluidService from '../../../assets/Services/FluidManagement/fluidMangement.jpg'
import imgOilAnalysis from '../../../assets/Services/FluidManagement/oilAnalysis.jpg'
import imgOilCleaning from '../../../assets/Services/FluidManagement/oilCleaning.jpg'
import imgFluidCard3 from '../../../assets/Services/FluidManagement/fluid_service.jpg'
import imgAvailability from '../../../assets/Services/FluidManagement/24_7_availability.jpg'
import imgAllAboutOil from '../../../assets/Services/FluidManagement/all_about_oil.jpg'
import imgEverythingOilDo from '../../../assets/Services/FluidManagement/everything_oil_must_do.jpg'
import imgOilchnaging from '../../../assets/Services/FluidManagement/more_than_changing_oil.jpg'
import imgMonitoring from '../../../assets/Services/FluidManagement/monitoring.jpg'
import imgPromotorImage from '../../../assets/Services/FluidManagement/promotor.png'
// import pdf from '../../assets/Services/FluidManagement/'


const FluidService = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const oilAnalysis = {
        image: imgOilAnalysis,
        title: 'Comprehensive oil analysis',
        description: "Whether it's mobile or stationary hydraulic systems: oil analysis forms the basis for optimal oil treatment.Only if fluid - related issues are detected in a timely manner and measures are taken to address them, can costly downtime and major replacements be avoided.Therefore, HANSA- FLEX Fluid Service offers comprehensive oil analyses on-site and in the laboratory.",
        buttonName: "READ MORE"
    }

    const oilCleaning = {
        image: imgOilCleaning,
        title: 'Efficient oil cleaning',
        description: 'By regularly filtering your oil, you can ensure that your hydraulic systems continue to function properly for longer periods. Solid and liquid impurities cause abrasion, increase wear, and promote corrosion. HANSA-FLEX fluid experts professionally clean your oil and find perfect filtration solutions for your application field.',
        buttonName: "READ MORE"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Everything works better",
            description: "Less damage from contamination, fewer failures, and less downtime",
            image: imgMark
        },
        {
            title: "Extended lifespan",
            description: "Your machines and systems last much longer",
            image: imgMark
        },
        {
            title: "Professional expertise",
            description: "Our trained fluid experts take care of your oil and prevent damage to your systems",
            image: imgMark
        },
        {
            title: "Knowing what's wrong",
            description: "Specialized diagnostics uncover what's wrong before it causes costly damage",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Everything oil should do",
            description: "Hydraulic oil must withstand high stresses. The DIN 51524 pressure fluid standard specifies the minimum requirements for hydraulic fluids. Other requirements:",
            listItems: ["Shear stability (resistance to viscosity changes)", "Filtration capacity", "Low hygroscopicity (the tendency of oil to absorb water) and resistance to thermal loads"],
            image: imgEverythingOilDo
        },
        {
            title: "More than just oil changes",
            description: "Fluid management involves more than just regular oil changes. Oil cleaning and analysis can help prevent failures and downtime. When a hydraulic system has been carefully designed to achieve specific performance, the requirements of the components used, such as fluid and filtration solutions, are particularly high. With professional advice from fluid experts, you can lay the groundwork for:",
            listItems: ["Operational reliability", "Long service life", "Economic operation"],
            image: imgOilchnaging
        },
        {
            title: "Fluid state monitoring",
            description: "Our experts continuously monitor oil condition using oil condition sensors. This allows changes such as increased contamination levels to be recognized and rectified. The advantages:",
            listItems: ["Detect anomalies in systems during operation", "Quick correction of damages", "Reduced risk of equipment, machinery, and system failures", "Continuous optimization of fluid management"],
            image: imgMonitoring
        },
    ];

    const tabTitles = [
        { title: "Requirements", eventKey: "Requirements" },
        { title: "Fluid management", eventKey: "Fluid management" },
        { title: "Control", eventKey: "Control" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgFluidService}
                    title={"360° FLUID MANAGEMENT WITH HANSA-FLEX"}
                    paragraph={"Oil care is a key factor in ensuring smooth operation of hydraulic systems and installations. Contaminants in the fluid can have serious effects. From analysis, through fluid cleaning, to continuous monitoring of the state during operation, HANSA-FLEX fluid experts support you in all aspects of oil treatment for your facilities and machines."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'COMPREHENSIVE CARE FOR HYDRAULIC OIL FROM EXPERTS'}
                    subtitle={'With the advantage of the know-how of HANSA-FLEX fluid experts, you can avoid damage and downtime'}
                />
                <ImageText
                    data={oilAnalysis} />
                <ImageText
                    data={oilCleaning} left={false} />
            </div>

            {/* // TODO: 10 Rules element ----Not made */}
            {/* // TODO: Client reviews */}

            <div>
                <SectionHeader
                    title={"OIL CARE - WHY IT'S WORTH IT"}
                    subtitle={"All advantages at a glance"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"THE 3 MOST IMPORTANT FACTS ABOUT HYDRAULIC OIL USAGE"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"DAMAGE DETECTION AND SYSTEM OPTIMIZATION RELATED TO FLUIDS"}
                    subtitle={"In case of damage, experienced hydraulic and fluid system experts from HANSA-FLEX conduct an on-site analysis. They quickly determine the causes of the breakdown and provide comprehensive support in implementing all necessary measures for damage elimination. Additionally, experts develop an action plan for your fluid management, so that oil-related system failures become a thing of the past."}
                />
                <Promoter
                    title={"All about clean hydraulic oil in a nutshell"}
                    checklistItems={["Hydraulic fluid is an important system component and has crucial significance for the design, operation, and maintenance of hydraulic equipment.", "You can find everything you need to know about the underestimated component hydraulic oil in our brochure."]}
                    imageUrl={imgPromotorImage}
                    btnLabel1={"Download PDF"}
                    btnLabel1Link={'pdfLink'}
                // add pdf link
                />
            </div>

            <div>
                <SectionHeader
                    title={"SOMETHING ELSE THAT MIGHT INTEREST YOU"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgAllAboutOil}
                                title="All About Oil: Fluid Service"
                                desc={"HANSA-FLEX's Fluid Service performs oil analyses directly on-site, performs oil cleaning or complete fluid replacement, and provides advice on all aspects of fluid..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgAvailability}
                                title="24/7 Availability: Rapid Hydraulic Service"
                                desc={"We're here for you in case of emergency, all the time: our Rapid Hydraulic Service can quickly replace faulty components, such as hose lines."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgFluidCard3}
                                title="You Can Count on Us: Industrial Service"
                                desc={"Experienced specialists support you as qualified persons in inspecting your facilities and equipment according to current legal regulations..."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )
}

export default FluidService