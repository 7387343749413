import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/Cylinders/Stage.jpg'
import imgStandardCylinder from '../../../assets/Products/DriveAndControlTechnology/Cylinders/StandardCylinder.jpg'
import imgCustomerSpecific from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CustomerSpecific Cylinder.jpg'
import imgTesting from '../../../assets/Products/DriveAndControlTechnology/Cylinders/Testing.jpg'
import imgChoiceOfColour from '../../../assets/Products/DriveAndControlTechnology/Cylinders/ChoiceOfColour.jpg'
import imgCorrosionProtection from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CorrosionProtection.jpg'
import imgServices from '../../../assets/Products/DriveAndControlTechnology/Cylinders/Services.jpg'
import imgHydraulicCylinder from '../../../assets/Products/DriveAndControlTechnology/Cylinders/HydraulicCylinder.jpg'
import imgCylinderWithInductive from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CylinderWithInductive.jpg'
import imgCylinderWithValveTech from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CylinderWithValveTech.jpg'
import imgHydraulicCylinderComponent from '../../../assets/Products/DriveAndControlTechnology/Cylinders/HydraulicCylinderComponent.jpg'
import imgCylinderRepair from '../../../assets/Products/DriveAndControlTechnology/Cylinders/CylinderRepair.jpg'
import imgSealingTech from '../../../assets/Products/DriveAndControlTechnology/Cylinders/SealingTech.jpg'
import imgHydraulicUnit from '../../../assets/Products/DriveAndControlTechnology/Cylinders/HydraulicUnit.jpg'


const Cylinder = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const cylinder1 = {
        image: imgStandardCylinder,
        title: 'Order standard cylinders now from the HANSA‑FLEX online shop',
        description: 'Our online shop has numerous single- and double-acting plunger cylinders up to 200 bar with standardised dimensions and, if required, mounting elements on the rod and base.',
        listItems: ["Permissible temperature: -15 °C to +80 °C", "Various sealing materials (NBR, Viton, PTFE)", "High-strength steel piston rod (200 h resistance in NSS test)"],
    }

    const cylinder2 = {
        image: imgCustomerSpecific,
        title: 'Customer-specific manufacture of new cylinders',
        description: 'HANSA‑FLEX manufactures hydraulic cylinders as replacement parts, prototypes or new product lines to your individual requirements as one-off, small batch or series manufactured items. The cylinders, which are designed for the intended application and use standardised components, provide an attractive price-performance ratio combined with easy procurement of spare parts.',
        listItems: ["Various pressure ranges up to max. 350 bar", "Comprehensive advice, creation of 3D models", "Customer-specific materials, surface treatments and paint finishes"],
    }

    const cylinder3 = {
        image: imgHydraulicCylinder,
        title: 'Hydraulic cylinders with integrated displacement measuring systems',
        description: 'We equip cylinders with displacement transducer systems for precise positioning, actuating and control movements. A continuous position signal supplies precise real-time data about the cylinder movement.',
        listItems: ["Precise reporting of the cylinder position and speed", "Displacement measuring systems sourced from a renowned manufacturer", "Wide choice of sensor type (including digital, analogue, capacitive)", "Cylinders of any size can be manufactured", "Reliable signal generation under all conditions"],
    }

    const cylinder4 = {
        image: imgCylinderWithInductive,
        title: 'Hydraulic cylinders with inductive limit switches',
        description: 'Position switches are a simple and cost-effective alternative to linear displacement transducers where the movement of a piston rod needs to be determined. Inductive, pressure-resistant proximity switches are attached in the end position of the hydraulic cylinder. If the piston reaches one of the two end positions, the relevant proximity switch transmits an electronic signal.',
        listItems: ["Reliable and precise position determination", "Contactless, wear-free switching", "Precise end position polling and high repeat accuracy"],
    }

    const cylinder5 = {
        image: imgCylinderWithValveTech,
        title: 'Hydraulic cylinders with valve technology',
        description: 'Protect people and machines! We offer hydraulic cylinders with integrated valve technology for even better safety. The safety measures can reliably ensure braking of the cylinder movement and prevent unintentional lowering, even if a hose line has failed.',
        listItems: ["Valves can be installed in the cylinder base or on the cylinder", "Various load-holding and releasable check valves", "Experienced fluid specialists configure the valve technology in-house"],
    }

    const cylinder6 = {
        image: imgHydraulicCylinderComponent,
        listItems: ["Cylinder strokes up to 5,000 mm, piston diameter up to 600 mm", "Various working pressures from 200 to 350 bar", "Standard cylinders with and without mountings", "Single-and double-acting cylinders", "Differential, tandem, synchronising and plunger cylinders", "Mounting elements, cylinder tubes, piston rods and accessories"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Poetry in motion",
            description: "Low-friction operation produces soft start and smooth movements",
            image: imgMark
        },
        {
            title: "Long service life",
            description: "High-strength piston rods made from carbon steel alloy absorb the hardest shocks",
            image: imgMark
        },
        {
            title: "Easy servicing",
            description: "Replaceable sealing bushes mean servicing is quick and productivity remains high",
            image: imgMark
        },
        {
            title: "High availability",
            description: "Perfectly matched cylinder tube seals prevent leaks, even under pressure shocks",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Comprehensively tested, high-quality cylinders",
            description: "Manufactured out of high-strength steel or, if required, stainless steel, all HANSA‑FLEX cylinders meet the highest quality standards and undergo thorough testing on modern test benches.",
            listItems: ["Extensive test processes including test report", "Pressure, leakage and function tests in accordance with standards", "Setting of sensors and valve technology", "Supplied ready to install"],
            image: imgTesting
        },
        {
            title: "Your hydraulic cylinder in your choice of colours",
            description: "Our customised cylinders not only match your technical requirements; we also paint them so that they blend in perfectly with the rest of your machine or system.",
            listItems: ["Painted in various colours of the customer’s choice", "Corrosivity category up to C5-M", "Available as one-off, small batch or series produced products"],
            image: imgChoiceOfColour
        },
        {
            title: "Surface treatment for maximum corrosion protection",
            description: "The piston rod material used for HANSA‑FLEX hydraulic cylinders has a corrosion resistance of up to 1000 h verified in salt spray (fog) tests performed in accordance with ISO 3768, class 9 ISO 4540, depending on the material grade and surface coating.",
            listItems: ["Steel (Ck45) hard chrome plated", "Steel (20MnV6) hard chrome plated", "Stainless steel (1.4057) hard chrome plated", "Steel (20MnV6) nickel plated and hard chrome plated", "Steel (42CrMo4V) surface-hardened and hard chrome plated"],
            image: imgCorrosionProtection
        },
        {
            title: "Everything from a single source",
            description: "HANSA‑FLEX manufactures systems and cylinders under one roof. This allows us to offer our customers a perfectly matched overall system with an attractive price-performance ratio.",
            listItems: ["Cylinders designed to match the hydraulic unit", "Manufacture of hydraulic systems including all components", "Installation by HANSA‑FLEX Industrial Service if required", "Tests performed during and after bringing into first use"],
            image: imgServices
        },
    ];

    const tabTitles = [
        { title: "Testing", eventKey: "Testing" },
        { title: "Choice of colours", eventKey: "Choice of colours" },
        { title: "Corrosion protection", eventKey: "Corrosion protection" },
        { title: "Services", eventKey: "Services" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"SALE AND MANUFACTURE OF HYDRAULIC CYLINDERS IN ALL SIZES"}
                    paragraph={"Hydraulic cylinders perform extremely heavy work. As the most important drive elements in hydraulics, they convert the pressure energy of the hydraulic fluid into mechanical energy. Cylinders can transmit very high forces while producing uniform and precise movements. HANSA‑FLEX sells a wide range of single- and to double-acting standard cylinders and manufactures customised hydraulic cylinders up to 8,000 mm in height."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULIC CYLINDERS FOR ALL FIELDS OF APPLICATION"}
                />
                <ImageText
                    data={cylinder1} />
                <ImageText
                    data={cylinder2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"PURCHASE HYDRAULIC CYLINDERS FROM THE FLUID SPECIALISTS"}
                    subtitle={"In its own manufacturing facilities, which have a high level of vertical integration, HANSA‑FLEX manufactures high-quality hydraulic cylinders and offers very good price-performance ratios with impressive delivery times, even for special articles."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"OTHER HYDRAULIC UNIT COMPONENTS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"INTEGRATED SENSORS AND VALVE TECHNOLOGY"}
                    subtitle={"We equip hydraulic cylinders with integrated valve technology and sensors for additional safety functions, precise positioning, trouble-free driving of different movement axes or for automation of movement sequences."}
                />
                <ImageText
                    data={cylinder3} />
                <ImageText
                    data={cylinder4}
                    left={false} />
                <ImageText
                    data={cylinder5} />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULIC CYLINDERS AND CYLINDER COMPONENTS FROM HANSA‑FLEX"}
                    subtitle={"Our online shop offers a large selection of single- and double-acting cylinders, various components and accessories. We also manufacture special cylinders to drawings or to meet the customer’s requirements."}
                />
                <ImageText
                    data={cylinder6} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCylinderRepair}
                                title="Cylinder repairs “made in Königshofen”"
                                desc={"The HANSA‑FLEX cylinder repair centre overhauls hydraulic cylinders of all sizes up to 8,000 mm and repairs hydraulic pumps, valves and motors."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSealingTech}
                                title="Sealing technology for every situation"
                                desc={"HANSA‑FLEX always has the perfect sealing solution. More than 8,000 standard seals available from stock and an in-house manufacturing centre for special seals."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHydraulicUnit}
                                title="Hydraulic units built to the customer’s requirements"
                                desc={"From planning, project management to the commissioning of hydraulic unit and plant engineering projects, HANSA‑FLEX draws on its knowledge and expertise to help.."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default Cylinder