import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgDigital_Centerspread from '../../../assets/Services/Digital Services/Digital Services/Digital_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgDigital_Imagetext_01 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_01.jpg'
import imgDigital_Imagetext_02 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_02.jpg'
import imgDigital_Imagetext_03 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_03.jpg'
import imgDigital_Imagetext_04 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_04.jpg'
import imgDigital_Imagetext_05 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_05.jpg'
import imgDigital_Imagetext_06 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagetext_06.jpg'


import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgDigital_Imagecard_01 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagecard_01.jpg'
import imgDigital_Imagecard_02 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_02.jpg'
import imgDigital_Imagecard_03 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagecard_03.jpg'
import imgDigital_Imagecard_04 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_04.jpg'
import imgDigital_Imagecard_05 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_05.jpg'
import imgDigital_Imagecard_06 from '../../../assets/Services/Digital Services/Digital Services/Digital_Imagcard_06.jpg'

import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'


const DigitalServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Digital_Imagetext_01 = {
    image: imgDigital_Imagetext_01,
    title: 'Order fluid technology articles conveniently online',
    listItems: ['Visit the user-friendly HANSA‑FLEX online shop with more than 80,000 articles relating to fluid technology. Download important information about each article, such as the applicable standards, safety data sheets and technical drawings. Two redundantly stocked central warehouses are your guarantee of high product availability and less downtime. Automated packing lines ensure your ordered articles are dispatched quickly.'],
  }

  const Digital_Imagetext_02 = {
    image: imgDigital_Imagetext_02,
    title: 'Maintenance management for fluid systems made easy',
    listItems: ['The X-CODE Manager service portal makes managing the maintenance of your hydraulic hose lines much easier. Create and administer your own database containing all the relevant information about your hydraulic hose lines, so that you always have a complete picture of your machines, components and scheduled dates. This enables you to optimise the forward planning of your servicing tasks. Detailed information about X-CODE Manager can be found here.'],
  }

  const Digital_Imagetext_03 = {
    image: imgDigital_Imagetext_03,
    title: 'Advice from experienced experts',
    listItems: ['There is no way of avoiding digitalisation. We work with you to check which of our digital solutions is most suitable for integration into your value chain.First your HANSA‑FLEX adviser identifies with you the worthwhile optimisation potential that could be supported or optimised through our digital solutions. The solution we arrive at together is the one most suitable for your requirements.'],
  }

  const Digital_Imagetext_04 = {
    image: imgDigital_Imagetext_04,
    title: 'Including infrastructure if required',
    listItems: ['Our goal is for you to gain maximum benefit for the minimum effort. For example, with our scanner solutions, we are happy to offer you everything you need for digital demand management:1.Scanners 2. Coded labels and 3. Shelves and containers.'],
  }

  const Digital_Imagetext_05 = {
    image: imgDigital_Imagetext_05,
    title: 'Developed together with the customer',
    listItems: ['With our digital services, the focus is always on user-friendliness. Every program and digital application from HANSA‑FLEX undergoes several test phases where the emphasis is on the wishes and suggestions of our customers.On request, your contact person will be happy to instruct you and your team in our digital applications and answer all your questions, so that you can get off to a quick and successful start..'],
  }

  const Digital_Imagetext_06 = {
    image: imgDigital_Imagetext_06,
    title: '',
    listItems: ['In-house development and programming by HANSA‑FLEX experts Competent advice from qualified specialists Consistent advice from a named contact person Implementation of all required updates through HANSA‑FLEX'],
  }






  const featuresData = [
    {
      title: "Use the same login details",
      description: "Your login details as a HANSA‑FLEX customer can also be used for the online shop and all the apps.",
      image: imgMark
    },
    {
      title: "Applications quickly learned",
      description: "Intuitive user interfaces make our digital applications very easy to learn..",
      image: imgMark
    },
    {
      title: "Save resources",
      description: "HANSA‑FLEX digital services save you operational steps, time and paper.",
      image: imgMark
    },

    // Add more objects for additional FeaturesCard data
  ];







  return (
    <div>
      <CenterStage
        imageSrc={imgDigital_Centerspread}
        title={"SMART SOLUTIONS FOR FLUID TECHNOLOGY"}
        paragraph={"Discover HANSA‑FLEX digital services! Order fluid technology components quickly and conveniently in our online shop and ease the task of organising hose line maintenance with the X-CODE Manager service portal. Benefit from quicker, more error-free data transfer based on state-of-the-art scanner technology, user-friendly apps or an EDI interface between your and our merchandise management systems."}
      />

      <SectionHeader
        title={"HANSA‑FLEX ONLINE SHOP AND X-CODE SERVICE PORTAL"}
        subtitle={""}
      />

      <div >
        <br />
        <ImageText data={Digital_Imagetext_01} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={Digital_Imagetext_02} left={false} /> <br /><br />
      </div>

      <SectionHeader
        title={"MORE EFFICIENT PROCESSES WITH DIGITAL SERVICES"}
        subtitle={"Smart HANSA‑FLEX solutions allow you to submit orders directly without a separate enquiry, determine material requirements in seconds and integrate deliveries precisely into your processes."}
      />


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Everything about the HANSA‑FLEX online shop" desc="The HANSA‑FLEX online shop makes selecting and ordering products quick, easy and convenient. Find out today about the numerous functions and advantages!" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgDigital_Imagecard_02} learnMoreLink="/metal-hoses" title="Goods ordering made easier with scanner solutions" desc="Use HANSA‑FLEX scanner solutions to place replenishment orders for fluid technology components or query the status of your hose lines when you are on the road." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Digital interface to HANSA‑FLEX via EDI and BMEcat" desc="We offer selected major customers the option of electronically interfacing with master data and orders to make your e-commerce processes even more efficient." />
          </Col>

        </Row>
      </Container>


      <SectionHeader
        title={"DIGITALISATION WITHOUT HURDLES"}
        subtitle={"HANSA‑FLEX’s digital applications are designed to be extremely practical and user-friendly so that users find the switch from analogue to digital as simple and straightforward as possible."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <SectionHeader
        title={"SYSTEMATIC DIGITAL SERVICES"}
        subtitle={"HANSA‑FLEX is a system provider for everything to do with fluid technology. This means we offer you individualised solutions thoroughly thought out from concept to completion and expert advice. The same applies of course for our digital services."}
      />


      <div >
        <br />
        <ImageText data={Digital_Imagetext_03} left={false} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={Digital_Imagetext_04} /> <br /><br />
      </div>

      <div >
        <br />
        <ImageText data={Digital_Imagetext_05} left={false} /> <br /><br />
      </div>

      <SectionHeader
        title={"DIGITAL SERVICES IN THE BEST HANSA‑FLEX QUALITY"}
        subtitle={"Just as much know-how and experience are invested in digital services made by HANSA‑FLEX as in our fluid technology components. That is something you can count on."}
      />

      <div >
        <br />
        <ImageText data={Digital_Imagetext_06} /> <br /><br />
      </div>


      <SectionHeader
        title={"FURTHER SERVICES FROM HANSA‑FLEX"}
        subtitle={""}
      />



      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Logistics solutions from HANSA‑FLEX" desc="While you manufacture the products, we ensure reliable material replenishment and support you with digital solutions to optimise your procurement channels.." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgDigital_Imagecard_05} learnMoreLink="/metal-hoses" title="Training with HANSA‑FLEX and IHA" desc="Take part, including remotely, in our basic and advanced training programme presented in association with the IHA and benefit from the know-how of experienced experts." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgDigital_Imagecard_06} learnMoreLink="/Industrial-hoses" title="Sensor technology for efficient fluid management" desc="Extend the service life and increase the availability of your systems and machines with oil condition monitoring by means of sensors. Our colleagues are happy to advis." />
          </Col>

        </Row>
      </Container>




    </div>
  )
}

export default DigitalServices;