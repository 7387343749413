import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgExpansion_centerstage from '../../../assets/Products/Pipelines/Expansion joints/Expansion_centerstage.jpg'
import ImageText from '../../../Components/ImageText/ImageText'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgExpansion_imagetext_01 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_01.jpg'
import imgExpansion_imagetext_02 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_02.jpg'
import imgExpansion_imagetext_03 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_03.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgExpansion_imagecard_01 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_01.jpg'
import imgExpansion_imagecard_02 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_02.jpg'
import imgExpansion_imagecard_03 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const ExpansionJoints = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Expansion_imagetext_01 = {
    image: imgExpansion_imagetext_01,
    title: 'The pipe bending centre and branches work hand-in-hand',
    listItems: ['The experts in the HANSA‑FLEX pipe bending centre have the necessary skills and knowledge of pipe bending and fluid technology to master the most complex challenges. They work closely with the specialists from the HANSA‑FLEX branches. You can expect the best possible advice and an optimum path from conception to manufacture. On request, HANSA‑FLEX is happy to manufacture ready-to-install pipe sets and deliver them Just-In-Time directly to the customer’s installation site, so that the entire process after pipe bending runs smoothly..'],
  }

  const Expansion_imagetext_02 = {
    image: imgExpansion_imagetext_02,
    title: 'Checking the shape of pipe bends',
    listItems: ['The bending experts at HANSA‑FLEX determine all the data required for the bent pipe from the computer simulation. If the simulation shows that the degree of ovalisation in the bend area exceeds the 10 % limit applied in hydraulics, then an internal mandrel is used during bending to prevent the pipe cross section from narrowing.Reducing the ovalisation reduces turbulence in the pipe system and increases efficiency.'],
  }


  const Expansion_imagetext_03 = {
    image: imgExpansion_imagetext_03,
    title: 'Well-equipped production floor',
    listItems: ['To meet the diverse requirements for pipe geometry needs many more than one type of machine. The extensive range of machines in the pipe bending centre allows HANSA‑FLEX to perform a wide variety of bending tasks and produce the highest quality results.HANSA‑FLEX continually acquires new machines to ensure not only the highest precision but also maximum flexibility on the production floor. Around 20 further CNC machines augment a fully automated bending cell.'],
  }

  return (
    <div>
      <CenterStage
        imageSrc={imgExpansion_centerstage}
        title={"SAFE SYSTEMS WITH EXPANSION JOINTS FROM HANSA‑FLEX"}
        paragraph={"Expansion joints reliably accommodate changes in length and angle brought about by temperature differences, settlement of foundations or imprecise installation. They protect hose lines, machines and equipment from damage. Expansion joints also play a key role in damping vibrations from motors and turbines by preventing their transmission along exhaust pipes or compressed air lines."}
      />

      <SectionHeader
        title={"EXPANSION JOINTS FROM HANSA‑FLEX FOR ALL APPLICATIONS"}
        subtitle={""}
      />

      <div >

        <ImageText data={Expansion_imagetext_01} left={false} />
      </div>

      <div >

        <ImageText data={Expansion_imagetext_02} />
      </div>

      <div >

        <ImageText data={Expansion_imagetext_03} left={false} />
      </div>

      {/* TODO: Carousel */}

      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgExpansion_imagecard_01} learnMoreLink="/hydraulic-hoses" title="HANSA‑FLEX flange fittings are the ideal fittings for large" desc="HANSA‑FLEX flange fittings are ideal for large diameters, high-pressure applications and restricted installation situations." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgExpansion_imagecard_02} learnMoreLink="/metal-hoses" title="Made-to-measure bent pipes" desc="Precision pipes in proven HANSA‑FLEX quality are manufactured made-to-measure out of various materials in the HANSA‑FLEX pipe bending centre." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgExpansion_imagecard_03} learnMoreLink="/Industrial-hoses" title="Connected by pipe fittings" desc="Transitions between various components are always a point for careful consideration in hydraulic systems. Pipe fittings from HANSA‑FLEX create a reliable connection." />
          </Col>

        </Row>
      </Container>

    </div>
  )
}

export default ExpansionJoints