import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import ImageText from '../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';

import imgHydraulicSeminars from '../../assets/Services/HydraulicAcademy/HydraulicAcademy.jpg'
import imgInHouseSeminars from '../../assets/Services/HydraulicAcademy/InHouseSeminars.jpg'
import imgRightFromStart from '../../assets/Services/HydraulicAcademy/RightFromStart.jpg'
import imgRulesOfOilCare from '../../assets/Services/HydraulicAcademy/RulesOfOilCare.jpg'
import imgTheoryToPractical from '../../assets/Services/HydraulicAcademy/TheoryToPractical.jpg'
import imgTrainingFromFirst from '../../assets/Services/HydraulicAcademy/TrainingFromFirst.jpg'
import imgTrainingOnLatestTech from '../../assets/Services/HydraulicAcademy/TrainingOnLatestTech.jpg'

//TODO: TextPoster
//TODO: TextPoster
//TODO: Client review

const HydraulicAccademy = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const TrainingFromFirst = {
        image: imgTrainingFromFirst,
        title: 'Training from first principles to refresher courses',
        description: 'The range of courses and seminars offered by HANSA-FLEX and IHA is aimed at people whose daily work involves hydraulics. The hydraulic training offered ranges from basic level to much deeper specialized knowledge on individual topics, such as project planning or system maintenance. Practice-oriented training provides the opportunity to put theory learned in previous courses to the test. An essential qualification for individuals working in the field of hydraulics is training to become officially authorized persons in accordance with BetrSichV and TRBS 1203-2019. For experienced employees, there are refresher courses to update their knowledge on the current state of technology regarding engineering, application, and safety. All training sessions are conducted by certified experts.',
    }

    const TrainingOnLatestTech = {
        image: imgTrainingOnLatestTech,
        title: 'Training on the latest technologies and their utilization',
        description: 'IHA has modern and fully equipped seminar rooms at all its facilities and hydraulic training benches with the latest technology in Linz, Stockstadt, and Dresden. Here, participants enjoy learning about the daily tasks of hydraulic systems and how to design them to be more efficient, safer, and better suited to their purpose. Special mention should be made of the training available at the Coreum in Stockstadt am Rhein. In the Coreum center, which has several small construction yards specifically set up for training, HANSA-FLEX and IHA are accompanied by machine manufacturers. Participants can directly apply the hydraulic knowledge gained in practice under real conditions, which helps to consolidate the acquired knowledge.'
    }

    const InHouseSeminars = {
        image: imgInHouseSeminars,
        title: 'Internal seminars at IHA or client facilities',
        description: "In addition to training at various locations in Germany and abroad, IHA can also conduct internal training. This training can take place at an HANSA-FLEX branch nearby or at the client's facilities.If you are interested in training for your employees or have questions about training, please do not hesitate to contact us.We will be happy to assist you.",
        buttonName: "Contact Us",
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Market recognition for your expertise",
            description: "Properly trained and qualified personnel impress clients.",
            image: imgMark
        },
        {
            title: "Increased cost-effectiveness",
            description: "Greater knowledge and skills reduce the number of errors, simultaneously increasing productivity and process efficiency.",
            image: imgMark
        },
        {
            title: "Enhanced operational safety",
            description: "Participants become familiar with the legal provisions governing the responsibility of individuals and collectives.",
            image: imgMark
        },
        {
            title: "Training completion certificate",
            description: "Successfully completed training is certified by IHA and recognized nationally.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHydraulicSeminars}
                    title={"FLUID TECHNOLOGY AND PIPELINE TRAINING"}
                    paragraph={"Training is the best way to counteract the shortage of qualified personnel. HANSA-FLEX utilizes the active transfer of knowledge from experienced experts to trainees, employees, and clients. HANSA-FLEX offers training and education in the field of fluid technology and pipelines, as well as training programs for hydraulic specialists, which it offers in conjunction with its partner International Hydraulics Academy (IHA)."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'SPECIALIZED KNOWLEDGE FROM THEORY TO PRACTICE'}
                />
                <ImageText
                    data={TrainingFromFirst} />
                <ImageText
                    data={TrainingOnLatestTech} left={false} />
                <ImageText
                    data={InHouseSeminars} />
            </div>

            <div>
                <SectionHeader
                    title={"QUALIFICATIONS FORM THE BASIS FOR SUCCESS"}
                    subtitle={"The courses and seminars conducted jointly by HANSA-FLEX and IHA impart first-class technical knowledge, from which hydraulic companies and their clients benefit in terms of safety and quality."}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"YOU MIGHT ALSO BE INTERESTED IN"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgTheoryToPractical}
                                title="From theory to practical applications"
                                desc={"The Coreum training center offers construction sites and training benches where course participants can experience the power and functions..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgRulesOfOilCare}
                                title="The 10 golden rules of oil care"
                                desc={"Find out what to observe in fluid management and how HANSA-FLEX supports you with comprehensive services to ensure everything runs smoothly."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgRightFromStart}
                                title="Professional and consistent from the start"
                                desc={"Our task is to ensure your success. HANSA-FLEX is your experienced partner, always by your side, helping and advising from project conception to..."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>
    )

}

export default HydraulicAccademy