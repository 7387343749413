import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgKitting_Centerspread from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgKitting_Imagetext_01 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagetext_01.jpg'
import imgKitting_Imagetext_02 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagetext_02.jpg'
import imgKitting_Imagetext_03 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagetext_03.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgKitting_Imagecard_01 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagecard_01.jpg'
import imgKitting_Imagecard_02 from '../../../assets/Services/Logistics solutions/Kitting/Kitting_Imagecard_02.jpg'



const Kitting = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Kitting_Imagetext_01 = {
        image: imgKitting_Imagetext_01,
        title: 'Optimising your inventory',
        description: ['We create and maintain a standardised article list for you. The list forms the basis for efficient warehouse management. The article list defines the ongoing use of material and enables the efficient use of your storage areas. We identify all the material groups you require to ensure a smooth production flow. As a one-stop provider, we can reliably supply you with the defined standard material. This allows you to consolidate your supplier list and save administration costs when ordering goods. With HANSA‑FLEX, you can choose flexible delivery intervals to suit your developing needs.'],
    }

    const Kitting_Imagetext_02 = {
        image: imgKitting_Imagetext_02,
        title: 'Proper storage of components',
        description: ['Component should be stored so that their quality and shelf-life are not adversely affected by dust, liquids or large temperature fluctuations. The risk of mechanical damage to components due to objects falling or rolling onto them should be minimised. Our highly capable employees are always available to provide advice and practical assistance to support you with their vast practice-proven knowledge and skills. These include, for example, the systematic positioning of warehousing systems and goods to shorten the distances your employees have to walk and the time they spend searching for the required items. This ensures there is nothing left to interfere with accessibility to components and machines.'],
    }


    const Kitting_Imagetext_03 = {
        image: imgKitting_Imagetext_03,
        title: 'Solutions for high goods throughput',
        description: ['Larger companies require correspondingly more parts and having automated ordering and warehousing processes becomes more important. There comes a point when written recording of material requirements and manual entry of orders takes up too much time for the whole process to remain efficient. Speak with your usual HANSA‑FLEX customer adviser about your options for digitalising your ordering processes, for example with a scanner solution, or automating them with a kanban system. Another possible way of optimising a high goods throughput would be to adopt a kit approach to your stock of parts.'],
    }




    const featuresData = [
        {
            title: "Reduced warehousing costs",
            description: "With HANSA‑FLEX you can reduce your own warehousing costs.",
            image: imgMark
        },
        {
            title: "Short supply routes",
            description: "HANSA‑FLEX has numerous branches and is therefore always close enough to ensure quick delivery.",
            image: imgMark
        },
        {
            title: "Individualised advice",
            description: "You have a permanent contact on site who is familiar with the local circumstances.",
            image: imgMark
        },
        {
            title: "Maximum flexibility",
            description: "With HANSA‑FLEX you receive tailor-made kits, even at short notice.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];




    return (
        <div>
            <CenterStage
                imageSrc={imgKitting_Centerspread}
                title={"KITTING OPTIMISES LOGISTICS AND INSTALLATION"}
                paragraph={"As a system partner, HANSA‑FLEX provides ready-to-install kits containing components from third-party supplies as well as from HANSA‑FLEX. The production lines are guaranteed an uninterrupted supply of components. Our customers save time, increase throughput and reduce the failure quota."}
            />


            <SectionHeader
                title={"INSTALLATION KITS CONFIGURED TO SUIT THE CUSTOMER’S WISHES"}
                subtitle={"HANSA‑FLEX configures and supplies kits precisely customised to suit your requirements and wishes. The benefits for you are many and varied."}
            />

            <div >
                <br />
                <ImageText data={Kitting_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Kitting_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Kitting_Imagetext_03} left={false} />
            </div>


            <SectionHeader
                title={"GOOD REASONS TO ADOPT KITTING FROM HANSA‑FLEX"}
                subtitle={"Hydraulic and pneumatic kits from HANSA‑FLEX including preassembled components offer attractive benefits for manufacturers."}
            />


            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            {/* <SectionHeader
                title={"COMPREHENSIVE KNOW-HOW FROM PROJECT CONCEPTION TO WAREHOUSE MANAGEMENT"}
                subtitle={""}
            /> */}

            {/* TODO: redbanner_carrousel */}


            <SectionHeader
                title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                subtitle={""}
            />




            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgKitting_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Works-in-works manufacturing directly on site" desc="Bring HANSA‑FLEX’s know-how in-house and have our experienced technicians make the required components available Just-In-Sequence." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgKitting_Imagecard_02} learnMoreLink="/metal-hoses" title="Kanban delivery from HANSA‑FLEX" desc="With kanban delivery based on the pull principle from HANSA‑FLEX, you gain from Just-In-Time delivery, precisely when you need it, including directly into your." />
                    </Col>

                </Row>
            </Container>









        </div>
    )
}

export default Kitting