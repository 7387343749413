import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgMark from '../../../assets/mark.png'


import Stage from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Stage.jpg'
import Quick from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Quick.jpg'
import RepairKit from '../../../assets/Services/RepairAndOverHaul/PressureAccu/RepairKit.jpg'
import AllRound from '../../../assets/Services/RepairAndOverHaul/PressureAccu/AllRound.jpg'
import AuthorisedPerson from '../../../assets/Services/RepairAndOverHaul/PressureAccu/AuthorisedPerson.jpg'
import Replacement from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Replacement.jpg'
import ChangeOfLocation from '../../../assets/Services/RepairAndOverHaul/PressureAccu/ChangeOfLocation.jpg'
import EquipmentInStorage from '../../../assets/Services/RepairAndOverHaul/PressureAccu/EquipmentInStorage.jpg'
import MoreTips from '../../../assets/Services/RepairAndOverHaul/PressureAccu/MoreTips.jpg'
import Inspection from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Inspection.jpg'
import Gas from '../../../assets/Services/RepairAndOverHaul/PressureAccu/Gas.jpg'
import PreventWear from '../../../assets/Services/RepairAndOverHaul/PressureAccu/PreventWear.jpg'


const PressureAccumulatorsAndAssociatedSystems = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const QuicklyBack = {
    image: Quick,
    title: 'Quickly back in working order',
    description: 'The fluid experts are qualified as officially authorised persons for pressure equipment. They are able to support you in all the work involved in replacing defective bladders, diaphragms and seals.',
    listItems: [
      "Support in the preparation of risk assessments",
      "Replacement and repair of defective pressure accumulators",
      "Nitrogen filling",
      "Checking gas pressure and oil valve on base",
      "Fault finding after sudden failure"]

  }

  const RepairKits = {
    image: RepairKit,
    title: 'Excellent range of machinery and equipment ',
    description: 'The HANSA‑FLEX cylinder repair centre has a range of modern machinery and equipment for cylinder repairs. Sandblasting and special paint coatings are a matter of course for us.',
    listItems: [
      "Conventional and CNC-controlled lathes",
      "Mounting holes for vertical installation and removal of cylinders",
      "Test bed in accordance with DIN 10100 for comprehensive functional tests",
      "Horning machine for overhauling damaged cylinder tubes"]

  }

  const featuresData = [
    {
      title: "Qualified specialist personnel",
      description: "All work on hydraulic accumulators is carried out by officially authorised persons for pressure equipment.",
      image: imgMark
    },
    {
      title: "No compromise on safety",
      description: "All the requirements of the German Industrial Health and Safety Act (BetrSichV) are observed.",
      image: imgMark
    },
    {
      title: "Comprehensive support",
      description: "We carry out all the tasks such as gas charging or the replacement of the accumulator bladder.",
      image: imgMark
    },
    {
      title: "Advice from experts",
      description: "We support you in the preparation of risk assessments and your inspection and testing plans.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  const data1 = {
    image: AllRound,
    description: "Our experts have many years of experience, extensive practical know-how and are completely familiar with the specific requirements of the various cylinder types.",
    listItems: [
      "Plunger and telescopic cylinders",
      "Press, crane and casting cylinders",
      "Fire fighting, water engineering, gas metering and closing cylinders",
      "Mobile hydraulic and pneumatic cylinders",
      "Standard and special cylinders"
    ]
  }

  return (
    <div>

      <div>
        <CenterStage
          imageSrc={Stage}
          title={'SERVICING AND REPAIR OF PRESSURE ACCUMULATORS'}
          paragraph={'Safe and reliable operation of pressure accumulator systems depends on regular maintenance including checks on the filling level and pressure of the gas charge. The servicing and repair of pressure accumulators require special knowledge. Therefore only officially authorised persons for pressure equipment in accordance with the European Pressure Equipment Directive and the German Industrial Health and Safety Act are allowed to perform this work.'}
        />

      </div>

      <div>
        <SectionHeader
          title={"SERVICES AND PRODUCTS FOR SERVICING HYDRAULIC ACCUMULATORS"}
          subtitle={"We recommend that an identical hydraulic accumulator is always kept on site as a replacement. By doing this you can avoid long standstills. You can find a large selection of pressure accumulators in the HANSA‑FLEX online shop. Other accumulators can be su"}
        />
        <ImageText data={QuicklyBack} left={false} />
        <ImageText data={RepairKits} />
      </div>


      <SectionHeader
        title={"HANSA‑FLEX’S SERVICING TEAMS OFFER ALL THESE ADVANTAGES"}
        subtitle={"With HANSA‑FLEX, the leading experts in fluid technology, you always have an experienced partner at your side."}
      />
      <FeatureCardCarousel featuresData={featuresData} />


      <div>
        <SectionHeader
          title={"REPAIR OF CYLINDERS OF ALL TYPES AND MANUFACTURE"}
          paragraph={"Our experienced hydraulics technicians repair over 6,000 hydraulic cylinders of all sizes, manufacturers and types every year."}
        />
        <ImageText
          data={data1} />
      </div>

      <div>
        <SectionHeader
          title={'WHAT TO CONSIDER WHEN REPLACING HYDRAULIC ACCUMULATORS'}
          subtitle={''}
        />

      </div>

      {/* TODO: Client review */}

      <div>
        <SectionHeader
          title={'TRAINING AND SEMINARS ON PRESSURE ACCUMULATORS IN HYDRAULIC SYSTEMS'}
          subtitle={'In cooperation with the International Hydraulics Academy (IHA), HANSA‑FLEX runs seminars for installation engineers and pressure accumulator maintenance personnel. Experts pass on their specialist knowledge of the safe operation, proper filling and repair of pressure accumulators.'}
        />
      </div>

      {/* TODO:HFTab */}

      <div>
        <SectionHeader
          title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
        />
      </div>
      <div>
        <Container className='custom-container'>
          <Row>
            <Col sm={12} md={6} lg={4} ><ImageCard image={Inspection}
              title="Inspection of your pressure accumulator by an officially authorised person"
              desc={"The fluid experts from HANSA‑FLEX are officially authorised persons for pressure equipment. They carry out external visual inspections and provide inspection reports."}
              buttonName="READ MORE" /></Col>

            <Col sm={12} md={6} lg={4} ><ImageCard image={Gas}
              title="Gas-Filled pressure accumulator from HANSA-FLEX"
              desc={"The HANSA‑FLEX range includes numerous high-quality diaphragm and bladder accumulators for various designs from renowned manufacturers."}
              buttonName="READ MORE" /></Col>

            <Col sm={12} md={6} lg={4} ><ImageCard image={PreventWear}
              title="Prevent wear"
              desc={"The cleanliness of the hydraulic fluid affects the service life of components. Oil cleaning and regular analyses help you avoid system breakdowns."}
              buttonName="READ MORE" /></Col>
            {/* // TODO: Button Link  */}
          </Row>
        </Container>

      </div>




    </div>
  )
}
export default PressureAccumulatorsAndAssociatedSystems


