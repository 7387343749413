import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams for routing
import QRContactCard from '../Components/QRContactCard/QRContactCard';
import imgDarryl from '../assets/business-cards/darryl.png'; // Example image
import imgLance from '../assets/business-cards/LanceRobinson.png'; // Example image
import imgRay from '../assets/business-cards/RayHolt.png'; // Example image
import imgDarren from '../assets/business-cards/DarrenSaunders.png'; // Example image
import imgMitchel from '../assets/business-cards/MitchellCross.png'; // Example image


const contacts = [
    {
        firstName: "Darryl",
        lastName: "Ballantyne",
        company: "HANSA-FLEX AUSTRALIA",
        title: "Managing Director",
        phone: "+61438057452",
        phone2: "0730265600", // Added second phone number
        email: "darryl.ballantyne@hansa-flex.com",
        address: "HANSA-FLEX Australia, Jijaws St 11/45, 4074 Sumner Park, Australia",
        image: imgDarryl,
        name: "darryl-ballantyne" // URL-friendly name
    }
    ,
    {
        firstName: "Lance",
        lastName: "Robinson",
        company: "HANSA-FLEX AUSTRALIA",
        title: "Sales VIC/TAS/SA",
        phone: "0438641744",
        phone2: "0393517500", // Added second phone number
        email: "lance.robinson@hansa-flex.com",
        address: "HANSA-FLEX Australia, 81 Enterprise Way, Sunshine West VIC 3020, Australia",
        image: imgLance,
        name: "lance-robinson" // URL-friendly name
    },
    {
        firstName: "Ray",
        lastName: "Holt",
        company: "HANSA-FLEX AUSTRALIA",
        title: "Sales QLD",
        phone: "0438662845",
        phone2: "0730265600", // Added second phone number
        email: "ray.holt@hansa-flex.com",
        address: "HANSA-FLEX Australia, Jijaws St 11/45, 4074 Sumner Park, Australia",
        image: imgRay,
        name: "ray-holt" // URL-friendly name
    },
    {
        firstName: "Darren",
        lastName: "Saunders",
        company: "HANSA-FLEX AUSTRALIA",
        title: "Regional Manager VIC/TAS/SA",
        phone: "+61498801400",
        phone2: "0393517500", // Added second phone number
        email: "darren.saunders@hansa-flex.com",
        address: "HANSA-FLEX Australia, 81 Enterprise Way, Sunshine West VIC 3020, Australia",
        image: imgDarren,
        name: "darren-saunders" // URL-friendly name
    },
    {
        firstName: "Mitchell",
        lastName: "Cross",
        company: "HANSA-FLEX AUSTRALIA",
        title: "Sales NSW",
        phone: "+61439978286",
        phone2: "0394767500",
        email: "mitchell.cross@hansa-flex.com",
        address: "HANSA-FLEX Australia, 18/40 Pavesi St, Smithfield, NSW 2164, Australia",
        image: imgMitchel,
        name: "mitchel-cross" // URL-friendly name
    }
];

function ContactCardsPage() {
    const { name } = useParams(); // Get the name parameter from the URL
    const contact = contacts.find(contact => contact.name.toLowerCase() === name.toLowerCase()); // Case-insensitive match

    return (
        <div className='container-contact-card'>
            {contact ? (
                <QRContactCard contactDetails={contact} contactImage={contact.image} />
                // <NewCard contactDetails={contact} contactImage={contact.image} />

            ) : (
                <p>Contact not found.</p>
            )}
            {/* Removed the links section */}
        </div>
    );
}

export default ContactCardsPage;
