import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import stage from '../../assets/Whoweare/stage-2.jpg'
import { MdEmail, MdFax, MdLocationOn, MdPhone } from 'react-icons/md';
import { Button, Col, Row } from 'react-bootstrap';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import NumberGrid from '../../Components/NumberGrid/NumberGrid';
import imgSouthAfrica from "../../assets/Whoweare/SouthAfrica.png";
import imgUAE1 from "../../assets/Whoweare/UAE1.jpg"
import imgSA2 from "../../assets/Whoweare/SA2.jpg"
import imgSA3 from "../../assets/Whoweare/SA3.jpg"
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import ImageText from '../../Components/ImageText/ImageText'
import imgSAFastdelivery from "../../assets/Whoweare/SAFastdelivery.jpg"
import imgSAcustomerssatisfaction from "../../assets/Whoweare/SAcustomerssatisfaction.jpg"
import HansaMap from "../../assets/Whoweare/HansaMap.png"
import imgSATechnicalexpertise from "../../assets/Whoweare/SATechnicalexpertise.jpg"
import imgSAmap from "../../assets/Whoweare/SAmap.gif"
import imgSAquality from "../../assets/Whoweare/SAquality.jpg"
import imgHFdubai from "../../assets/Whoweare/HFdubai.jpg"
import FullImageText from '../../Components/FullImageText/FullImageText';
import imgUAE2 from "../../assets/Whoweare/UAE2.png"
import imgBremen from "../../assets/Whoweare/Bremen.jpg"
import Promoter from '../../Components/Promoter/Promoter';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgUAESustainable from "../../assets/Whoweare/UAESustainable.png"
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import hose from '../../assets/Whoweare/HoseLogo.png';
import imgServiceVan from '../../assets/servicevan.png'
import imgCartons from '../../assets/Whoweare/Cartons.png';
import imgCustomerSupport from '../../assets/Whoweare/customer-support.png';
import imgService from '../../assets/Whoweare/service.jpg';
import imgLogistics from '../../assets/Whoweare/LogisticsImg.jpg';
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
const AboutAustrilia = () => {
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //hall of fame
    const numbers = ['3', '14'];
    const subtitles = ['No. of Branches', 'No. of Employees'];


    // const sealingTech4 = {
    //     image: SealTechnology,
    //     listItems: ["Standard and special seals", "Hydraulic and pneumatic seals", "Static seals", "Sealing rings, flat seals and moulded parts", "Measuring aids for determining the right seal", "Assembly tool"],
    // }

    //promoter content

    const promoterTitle = 'HANSA-FLEX Australia: Sustainability';
    const promoterChecklistItems = [
        'Eco-Friendly Practices: Promotes biodegradable fluids.',
        'Energy-Efficient Designs: They design energy-efficient hydraulic systems.',
        'Hydraulic Recycling: Promotes proper disposal and recycling of hydraulics',
        'Green Initiatives Collaboration: HANSA-FLEX supports reforestation, clean energy, and offers workshops on sustainable hydraulics.'
    ];

    // features card carousel data
    const featuresData = [
        {
            title: "Customer Service",
            description: "Offering dedicated assistance and guidance to our clients in Australia",
            image: imgCustomerSupport
        },
        {
            title: "FLEXXPRESS",
            description: "On-site repairs 24/7 - Mobile hydraulic service",
            image: imgServiceVan
        },
        {
            title: "Premium Quality products",
            description: "Experience our unmached Quality",
            image: hose
        },
        {
            title: "Extensive Stock Availability",
            description: "We have stocks of products around 13000 units!",
            image: imgCartons
        }
        // Add more objects for additional FeaturesCard data
    ];
    const carouselItems = [
        {
            imgSrc: imgService,
            title: 'Fast and Efficient Hydraulic Spare Parts Supply',
            description: 'At HANSA-FLEX Australia we provide best of customer service and take care that the quality product is delivered'
        },

        {
            imgSrc: imgLogistics,
            title: 'Advanced Engineering Integration ',
            description: 'HANSA‑FLEX Australia: HANSA-FLEX designs and manufactures innovative drive and control systems right up to the project planning of entire hydraulic installations.'
        }
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgBremen}
                    title="Welcome to HANSA-FLEX Australia "
                    paragraph="HANSA-FLEX Australia, part of the global HANSA-FLEX Group, began as Flanges Australia in 1990 and swiftly became a key player in hydraulic connections and accessories. Originally notable for projects like the Queensland Railways electrification, the company expanded its product range significantly in the late 2000s, including carbon steel tubes and various fittings. The 2014 acquisition by HANSA-FLEX AG and the 2015 acquisition of Rapid Hose Systems bolstered its market presence. On January 1, 2016, the company was officially renamed HANSA-FLEX Australia Pty Ltd, integrating fully into the HANSA-FLEX Group and enhancing its capabilities in the Asia Pacific region. " />

            </div>
            <br /><br />



            <FullImageText
                imageSrc={imgUAE1}
                altText="Stage"
                title="Systems supplier in the OEM business"
                paragraph="HANSA-FLEX is a global hydraulics solutions provider for OEMs. They offer services like component production, engineering, cylinder repair, and series production of hoses and pipelines"
                imageWidth={1}
            />

            <br />
            <br />

            <div className='div-gray'>
                <SectionHeader title={"Why choose us ?"} paragraph={"We are the epitome of excellence, renowned for our unwavering commitment to quality and customer satisfaction. We deliver unparalleled solutions tailored to your needs."} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div>

                <br /><br /><br /><br />
                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={1} />
            </div>
            {/* <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            // btnLabel1={"Find Out More !"}
            // btnLabel1Link={"/hoses-and-hose-lines"}
            imageUrl={imgUAESustainable}
          />
          // TODO: contact us button
        )}
      </div> */}
            <br />



            <div >


                <br /><br />
                <SectionHeader
                    title={"Quick facts about HANSA-FLEX Australia"} /><br />
                <NumberGrid numbers={numbers} subtitles={subtitles} /><br /><br />
            </div>

            <br />
            <br />
            <br />

            <div className="text-center div-gray">
                <SectionHeader
                    title={"Explore about HANSA-FLEX Group"}
                    paragraph={"HANSA‑FLEX is a global family-run company and one of the world’s leading system partners for fluid technology. In the last business year, more than 5,000 employees in 40 countries achieved a turnover of EUR 570 million."} />
                <br />
                <Button variant="danger" href={"https://www.hansa-flex.de/en/company/"} target="_blank" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>Learn more about HANSA-FLEX</Button>

                <br /><br /><br />
            </div>
            <br /><br /><br />
            <br />
            {/* <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>Contact Details:</h2>
                        <div>
                            <p>
                                <MdLocationOn /> Hansa-flex Australia  <br />
                                Dubai Industrial Park DWC Warehouse Unit 25B, <br />
                                Dubai
                            </p>
                            <p>
                                <MdFax /> Fax: +27 11 394 5836
                            </p>
                            <p>
                                <MdPhone /> Telephone: +27 11 394 2966
                            </p>
                            <p>
                                <MdEmail /> Email: <a href="mailto:hst@hansa-flex.com" style={{ color: 'white' }}>uac@hansa-flex.com</a>
                            </p>
                        </div>
                    </Col>
                    <br /><br />
                    <br /><br />
                </Row>
            </div> */}




        </div>
    )
}

export default AboutAustrilia