import React, { useEffect } from 'react';
import CenterStage from '../../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../../../Components/ImageText/ImageText';
import imgMark from '../../../../../assets/mark.png'
import FeatureCardCarousel from '../../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';

import Stage from '../../../../../assets/Services/PreventativeMaintainance/Licences/Stage.jpg'
import Stage1 from '../../../../../assets/Services/PreventativeMaintainance/Licences/1.jpg'
import Stage2 from '../../../../../assets/Services/PreventativeMaintainance/Licences/2.jpg'
import Stage3 from '../../../../../assets/Services/PreventativeMaintainance/Licences/3.jpg'
import Stage4 from '../../../../../assets/Services/PreventativeMaintainance/Licences/4.jpg'
import Stage5 from '../../../../../assets/Services/PreventativeMaintainance/Licences/5.jpg'
import Stage6 from '../../../../../assets/Services/PreventativeMaintainance/Licences/6.jpg'

import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../../Components/ImageCard/ImageCard';


const Licenses = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"LICENCE PACKAGES FOR X-CODE MANAGER"}
                    paragraph={"Use X-CODE Manager in the My.HANSA‑FLEX customer portal for intelligent, quick and easy management of your hydraulic hose lines and all other components that need to be regularly inspected, serviced and replaced. Optimise the procurement of replacement parts for these articles with the unmistakable X-CODE by HANSA‑FLEX and configure your customised maintenance database using X-CODE Manager."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"WHICH IS THE BEST LICENCE FOR YOUR COMPANY?"}
                    subtitle={"No two companies are the same. Therefore we offer a number of options. How many users you require, how many machines you operate and whether you would like inspections in accordance with BetrSichV performed by an experienced partner or handle them yourself all form part of the decision on the choice of licence package."}
                />
                <Container className="custom-container">
                    <Row>
                        {/* <Col sm={12} md={6} lg={12}><ImageCard image={Stage1}
                            title={"ddf"} desc={"dfjjhdsjfh"} /></Col>

                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage2} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>

                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage3} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>
                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage4} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>
                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage5} title={"ddf"} desc={"dfjjhdsjfh"} /></Col>
                        <Col sm={12} md={6} lg={12}><ImageCard image={Stage6} title={"ddf"} desc={"dfjjhdsjfh"} /></Col> */}

                    </Row>
                </Container>
            </div>

            \
            <div>
                <SectionHeader
                    title={"Obtaining a licence"}
                    subtitle={"To obtain a licence please contact your customer adviser. Your adviser will explain the portal in more detail and work together with you to choose the correct licence. Should you at any time need more users than allowed for in your licence, you can add an add-on package of ten more users for EUR 30.00. You can add as many add-on packages as you need. As soon as you have decided on a particular licence, your customer adviser will discuss the licence agreement with you. A licence is obtained for a period of at least one year and paid for in advance. You have the flexibility to decide whether to take the licence only for a year or acquire one lasting several years. As soon as we receive the signed license agreement from you, we will make the necessary settings and, shortly after that, you will be able to use the extended functions in X-CODE Manager. If you do not yet have a personal HANSA‑FLEX customer adviser, please contact our customer services."}
                />
                <div className='ms-5'>
                    Tel.: +49 421 48907 766 <br />
                    E-mail: my@hansa-flex.de
                </div>
            </div>

        </div>
    )
}

export default Licenses