import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgAUBRISBANE from "../../assets/ContactLocation/AUBRISBANE.png"
import imgAUWALES from "../../assets/ContactLocation/AUWALES.png"
import imgAUVICTORIA from "../../assets/ContactLocation/AUVICTORIA.png"
import imgContactus from "../../assets/ContactLocation/Contactus.jpg"



const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX Australia Pty. Ltd., Brisbane',
            location: 'HANSA-FLEX Australia, Jijaws St 11/45, 4074 Sumner Park, Australia',
            telephone: '+61 7 3026 5600',
            fax: "+61 7 3279 2630",
            mail: 'bris.sales@hansa-flex.com',
            image: imgAUBRISBANE
        },


        {
            name: 'HANSA-FLEX Australia Pty. Ltd., Sydney',
            location: 'Unit 18/40 Pavesi St, 40 Pavesi St, Smithfield NSW 2164, Australia',
            telephone: '+61 2 9476 6620',
            fax: "+61 7 3279 2630",
            mail: ' syd.sales@hansa-flex.com',
            image: imgAUWALES
        },

        {
            name: 'HANSA-FLEX Australia Pty. Ltd., Melbourne',
            location: 'HANSA-FLEX Australia, Enterprise Way 81, 3020 Sunshine West, Australia',
            telephone: '+61 3 9351 7500',
            fax: "+61 3 9351 7599",
            mail: 'melb.sales@hansa-flex.com',
            image: imgAUVICTORIA
        },


    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgContactus}
                    title={"Get in touch with HANSA-FLEX Australia"}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how HANSA-FLEX Australia can take your hydraulic operations to new heights."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
