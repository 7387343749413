import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './EventsXCalendar.css';
import imgChristmasTrees from '../../assets/ChristmasImages/christmas tree.png'
import imgChristmasGarlend from '../../assets/ChristmasImages/christmas garlend.png'
import imgChristmasbells from '../../assets/ChristmasImages/christmas bells.png'


const EventsXCalendar = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    // Generate snowflakes
    const generateSnowflakes = () => {
      const snowflakeCount = 50;
      const newSnowflakes = Array.from({ length: snowflakeCount }, (_, index) => ({
        id: index,
        left: `${Math.random() * 100}%`,
        animationDuration: `${Math.random() * 10 + 5}s`,
        size: `${Math.random() * 3 + 1}px`
      }));
      setSnowflakes(newSnowflakes);
    };

    generateSnowflakes();

    // Apply styles for events-calendar-container
    document.body.classList.add('events-calendar-container');

    // Hide other elements on the page
    const navbar = document.querySelector('.secondary-navbar');
    const header = document.querySelector('.App-header');
    const footer = document.querySelector('.hf-footer-main-div');
    const primaryNav = document.querySelector('header.App-header');

    if (navbar) navbar.style.display = 'none';
    if (header) header.style.display = 'none';
    if (footer) footer.style.display = 'none';
    if (primaryNav) primaryNav.display = 'none';

    // Cleanup styles on component unmount
    return () => {
      document.body.classList.remove('events-calendar-container');
      if (navbar) navbar.style.visibility = 'visible';
      if (header) header.style.visibility = 'visible';
      if (footer) footer.style.visibility = 'visible';
    };
  }, []);

  return (
    <div className="events-calendar-container mt-5">
      {/* Snowfall effect */}
      {snowflakes.map((snowflake) => (
        <div 
          key={snowflake.id} 
          className="snowflake" 
          style={{
            left: snowflake.left,
            animationDuration: snowflake.animationDuration,
            width: snowflake.size,
            height: snowflake.size
          }}
        />
      ))}

      {/* Christmas Lights */}
      <div className="christmas-lights">
        {[...Array(5)].map((_, i) => (
          <span key={i} style={{animationDelay: `${-i * 0.2}s`}}></span>
        ))}
      </div>

       {/* Christmas Tree Decorations */}
       <div className="christmas-tree-container">
        {/* <img src={imgChristmasGarlend} alt="Christmas Tree" className="christmas-garlend" /> */}
        <img src={imgChristmasTrees} alt="Christmas Tree" className="christmas-tree" />
      </div>

      {/* Christmas Wreath */}
      <img src={imgChristmasbells} alt="Christmas Wreath" className="christmas-wreath" />
        <div className='event-calender-container-center'>

        <h2 className="events-calendar-header text-center mb-4">Christmas Closure Times</h2>
      <Table className="events-calendar-table" striped hover responsive>
        <thead>
          <tr>
            <th>Location</th>
            <th>Close</th>
            <th>Re-open</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Brisbane – Sydney</td>
            <td>Friday, 20th December 2024 @ 4.00pm</td>
            <td>Monday, 6th January 2025 @ 8.00am</td>
          </tr>
          <tr>
            <td>Melbourne</td>
            <td>Friday, 20th December 2024 @ 4.00pm</td>
            <td>Thursday, 2nd January 2025 @ 8.00am</td>
          </tr>
        </tbody>
      </Table>
        </div>
      
    </div>
  );
}

export default EventsXCalendar;